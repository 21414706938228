import React from 'react'
import { graphql } from 'gatsby'
import Scene from "../components/scene"

const IndexPage = ({ data: { home } }) => (
  <>
    <Scene />
  </>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    home: datoCmsHome {
      externalLink
      cheekyLogo {
        fluid(imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      bubble {
        fluid(imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`
