import React, { useEffect, useRef, useMemo } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { InstancedMesh } from "three";

const Scene = () => {
  const canvasEl = useRef(null);

  useEffect(() => {
    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    // Create a custom shader material for the animated gradient background
    const vertexShader = `
      uniform float time;
      uniform float noiseScale;
      uniform float noiseSpeed;
      varying vec2 vUv;
      
      //	Simplex 3D Noise 
      //	by Ian McEwan, Ashima Arts
      vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
      vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
      
      float snoise(vec3 v){ 
        const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
        const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);
      
        // First corner
        vec3 i  = floor(v + dot(v, C.yyy) );
        vec3 x0 =   v - i + dot(i, C.xxx) ;
      
        // Other corners
        vec3 g = step(x0.yzx, x0.xyz);
        vec3 l = 1.0 - g;
        vec3 i1 = min( g.xyz, l.zxy );
        vec3 i2 = max( g.xyz, l.zxy );
      
        vec3 x1 = x0 - i1 + 1.0 * C.xxx;
        vec3 x2 = x0 - i2 + 2.0 * C.xxx;
        vec3 x3 = x0 - 1. + 3.0 * C.xxx;
      
        // Permutations
        i = mod(i, 289.0 ); 
        vec4 p = permute( permute( permute( 
                   i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
                 + i.y + vec4(0.0, i1.y, i2.y, 1.0 )) 
                 + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));
      
        // Gradients
        float n_ = 1.0/7.0; // N=7
        vec3  ns = n_ * D.wyz - D.xzx;
      
        vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)
      
        vec4 x_ = floor(j * ns.z);
        vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)
      
        vec4 x = x_ *ns.x + ns.yyyy;
        vec4 y = y_ *ns.x + ns.yyyy;
        vec4 h = 1.0 - abs(x) - abs(y);
      
        vec4 b0 = vec4( x.xy, y.xy );
        vec4 b1 = vec4( x.zw, y.zw );
      
        vec4 s0 = floor(b0)*2.0 + 1.0;
        vec4 s1 = floor(b1)*2.0 + 1.0;
        vec4 sh = -step(h, vec4(0.0));
      
        vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
        vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;
      
        vec3 p0 = vec3(a0.xy,h.x);
        vec3 p1 = vec3(a0.zw,h.y);
        vec3 p2 = vec3(a1.xy,h.z);
        vec3 p3 = vec3(a1.zw,h.w);
      
        // Normalise gradients
        vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
        p0 *= norm.x;
        p1 *= norm.y;
        p2 *= norm.z;
        p3 *= norm.w;
      
        // Mix final noise value
        vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
        m = m * m;
        return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1), 
                                      dot(p2,x2), dot(p3,x3) ) );
      }
      
      void main() {
        vUv = uv;
        vec3 pos = position;
        float noiseFreq = noiseScale;
        float noiseAmp = 0.25; 
        vec3 noisePos = vec3(pos.x * noiseFreq + time * noiseSpeed, pos.y, pos.z);
        pos.z += snoise(noisePos) * noiseAmp;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
      }
    `;

    const fragmentShader = `
      uniform float time;
      varying vec2 vUv;
      void main() {
        vec3 color1 = vec3(0.0, 0.5, 1.0);
        vec3 color2 = vec3(0.5, 0.2, 0.5);
        float alpha = sin(time * 0.5 + vUv.x * 10.0) * 0.5 + 0.5;
        vec3 color = mix(color1, color2, alpha);
        gl_FragColor = vec4(color, 1.0);
      }
    `;

    const uniforms = {
      time: { value: 0.0 },
      noiseScale: { value: 1.0 },
      noiseSpeed: { value: 0.2 }
    };


    const gradientMaterial = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms
    });

    const gradientGeometry = new THREE.PlaneGeometry(2, 2);
    const gradientMesh = new THREE.Mesh(gradientGeometry, gradientMaterial);
    gradientMesh.material.depthTest = false;
    gradientMesh.material.depthWrite = false;

    const gradientScene = new THREE.Scene();
    const gradientCamera = new THREE.Camera();
    gradientScene.add(gradientMesh);
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    canvasEl.current.appendChild(renderer.domElement);

    // Add WASD controls
    const keys = {
      w: false,
      a: false,
      s: false,
      d: false
    };

    const handleKeyDown = (event) => {
      switch (event.key) {
        case "w":
          keys.w = true;
          break;
        case "a":
          keys.a = true;
          break;
        case "s":
          keys.s = true;
          break;
        case "d":
          keys.d = true;
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.key) {
        case "w":
          keys.w = false;
          break;
        case "a":
          keys.a = false;
          break;
        case "s":
          keys.s = false; 
          break;
        case "d":
          keys.d = false;
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    controls.dampingFactor = 0.25;
    controls.screenSpacePanning = false;
    controls.maxPolarAngle = Math.PI / 2;
    // Enhanced lighting setup
    const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x000000, 0.6);
    scene.add(hemisphereLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(5, 10, 5);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;
    scene.add(directionalLight);

    // Add multiple point lights for more dynamic lighting
    const pointLight1 = new THREE.PointLight(0xff9999, 0.5, 50);
    pointLight1.position.set(10, 5, 10);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0x99ff99, 0.5, 50);
    pointLight2.position.set(-10, 5, -10);
    scene.add(pointLight2);

    const pointLight3 = new THREE.PointLight(0x9999ff, 0.5, 50);
    pointLight3.position.set(0, -5, 0);
    scene.add(pointLight3);

    // Add a plane to receive the shadow
    const planeGeometry = new THREE.PlaneGeometry(10, 10);
    const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.5 });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.rotation.x = -Math.PI / 2;
    plane.position.y = -1;
    plane.receiveShadow = true;
    scene.add(plane);
    // Create rain particle system using instancing
    const rainCount = 5000; // Reduced from 10000
    const rainGeometry = new THREE.BufferGeometry();
    const rainPositions = new Float32Array(rainCount * 3);
    const rainVelocities = new Float32Array(rainCount * 3);

    for (let i = 0; i < rainCount; i++) {
      rainPositions[i * 3] = Math.random() * 400 - 200;
      rainPositions[i * 3 + 1] = Math.random() * 500 - 250;
      rainPositions[i * 3 + 2] = Math.random() * 400 - 200;
      rainVelocities[i * 3 + 1] = -Math.random() * 0.1 - 0.1; // Downward velocity
    }

    rainGeometry.setAttribute('position', new THREE.BufferAttribute(rainPositions, 3));
    rainGeometry.setAttribute('velocity', new THREE.BufferAttribute(rainVelocities, 3));

    const rainMaterial = new THREE.PointsMaterial({
      color: 0xaaaaaa,
      size: 0.5,
      transparent: true,
      sizeAttenuation: true,
      map: new THREE.CanvasTexture(createCircleTexture())
    });

    function createCircleTexture() {
      const size = 32; // Reduced from 128
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const context = canvas.getContext('2d');
      context.beginPath();
      context.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
      context.fillStyle = 'white';
      context.fill();
      return canvas;
    }

    const rain = new THREE.Points(rainGeometry, rainMaterial);
    scene.add(rain);

    // Add spheres using instancing
    const sphereCount = 30; // Reduced from 50
    const sphereGeometry = new THREE.SphereGeometry(0.5, 16, 16); // Reduced complexity
    const sphereMaterial = new THREE.MeshPhysicalMaterial({
      metalness: 0.1,
      roughness: 0.2,
      clearcoat: 1.0,
      clearcoatRoughness: 0.1,
      reflectivity: 1.0,
      envMapIntensity: 1.0,
    });

    const sphereInstanced = new InstancedMesh(sphereGeometry, sphereMaterial, sphereCount);
    sphereInstanced.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
    scene.add(sphereInstanced);

    const spherePositions = [];
    const sphereVelocities = [];
    const candyColors = [
      0xff69b4, 0x00ffff, 0xff6347, 0x7fffd4,
      0xffa500, 0x40e0d0, 0xff1493, 0x00ff7f,
    ];

    for (let i = 0; i < sphereCount; i++) {
      const position = new THREE.Vector3(
        (Math.random() - 0.5) * 10,
        (Math.random() - 0.5) * 10,
        (Math.random() - 0.5) * 10
      );
      spherePositions.push(position);

      const velocity = new THREE.Vector3(
        (Math.random() - 0.5) * 0.05,
        (Math.random() - 0.5) * 0.05,
        (Math.random() - 0.5) * 0.05
      );
      sphereVelocities.push(velocity);

      const color = new THREE.Color(candyColors[Math.floor(Math.random() * candyColors.length)]);
      sphereInstanced.setColorAt(i, color);

      const matrix = new THREE.Matrix4();
      matrix.setPosition(position);
      sphereInstanced.setMatrixAt(i, matrix);
    }

    sphereInstanced.instanceColor.needsUpdate = true;
    sphereInstanced.instanceMatrix.needsUpdate = true;

    // Position the camera
    camera.position.z = 5;

    let lastTime = 0;
    const animate = (currentTime) => {
      requestAnimationFrame(animate);

      const deltaTime = currentTime - lastTime;
      lastTime = currentTime;

      // Update spheres
      for (let i = 0; i < sphereCount; i++) {
        const position = spherePositions[i];
        const velocity = sphereVelocities[i];

        position.add(velocity);

        // Check for collisions with boundaries
        const bounds = 5;
        if (Math.abs(position.x) > bounds) velocity.x *= -1;
        if (Math.abs(position.y) > bounds) velocity.y *= -1;
        if (Math.abs(position.z) > bounds) velocity.z *= -1;

        const matrix = new THREE.Matrix4();
        matrix.setPosition(position);
        sphereInstanced.setMatrixAt(i, matrix);
      }
      sphereInstanced.instanceMatrix.needsUpdate = true;

      // Update rain particles
      const rainPositions = rain.geometry.attributes.position.array;
      const rainVelocities = rain.geometry.attributes.velocity.array;
      for (let i = 0; i < rainCount; i++) {
        rainPositions[i * 3 + 1] += rainVelocities[i * 3 + 1];
        if (rainPositions[i * 3 + 1] < -200) {
          rainPositions[i * 3 + 1] = 200;
        }
      }
      rain.geometry.attributes.position.needsUpdate = true;

      // Update camera position based on WASD keys
      const cameraSpeed = 0.1 * deltaTime / 16; // Normalize speed based on 60 FPS
      if (keys.w) camera.position.z -= cameraSpeed;
      if (keys.s) camera.position.z += cameraSpeed;
      if (keys.a) camera.position.x -= cameraSpeed;
      if (keys.d) camera.position.x += cameraSpeed;

      controls.update();

      // Update the time uniform for the gradient background and fluid animation
      gradientMaterial.uniforms.time.value += 0.01 * deltaTime / 16;
      gradientMaterial.uniforms.noiseScale.value = 1.0 + Math.sin(gradientMaterial.uniforms.time.value * 0.1) * 0.5;

      // Render the gradient background
      renderer.autoClear = false;
      renderer.clear();
      renderer.render(gradientScene, gradientCamera);

      // Render the main scene
      renderer.render(scene, camera);
    };

    requestAnimationFrame(animate);

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      canvasEl.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={canvasEl} />;
};

export default Scene;
